<div
    class="subscribe-area-two"
    *ngFor="let Image of bgImage"
    style="background-image: url({{ Image.img }});"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="subscribe-image">
                    <img src="assets/img/about-4.png" class="overlay-image mobile-view-pic" alt="image" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="subscribe-content">
                    <span class="sub-title">Get it on play store</span>
                    <h2 class="playfair-display-font">Download our APP Now</h2>
                    <p>
                        Future Vision Mobile App is now available for Android.
                        Learn anytime, anywhere just made for your ease of
                        learning.
                    </p>
                    <a href="https://play.google.com/store/apps/details?id=com.zerobugz.fvlms&hl=en" target="_blank">
                        <img class="cursor-pointer google-play-button" src="./assets/img/fv/play_store.png" alt="play_store" />
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image" /></div>
    <div class="shape13"><img src="assets/img/shape12.png" alt="image" /></div>
</div>
