<div class="section-title">
    <h2>Latest Updates</h2>
</div>
<div
    class="marquee-container"
    [ngClass]="{
        'marquee-container-height': page === 1,
        'marquee-container-height-2': page === 2
    }"
    (mouseover)="stopScroll()"
    (mouseout)="startScroll()"
>
    <ul class="marquee-content">
        <li *ngFor="let task of latestUpdates" class="marquee-card">
            <span [innerHTML]="task.news"></span>
            <a
                *ngIf="task.link"
                [href]="task.link"
                target="_blank"
                class="task-link"
                >Click here</a
            >
        </li>
        <!-- Duplicate the content for seamless looping -->
        <li *ngFor="let task of latestUpdates" class="marquee-card duplicate">
            <span [innerHTML]="task.news"></span>
            <a
                *ngIf="task.link"
                [href]="task.link"
                target="_blank"
                class="task-link"
                >Click here</a
            >
        </li>
    </ul>
</div>
