

<div class="gallery-area pt-5 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                <h2 class="mb-0 mt-3 text-center fw-bold" *ngIf="_album.length">
                    {{ courseName }} - Sample Materials
                </h2>
                <br>
                <div *ngIf="_album.length === 0" class="empty-message">
                    <p>No materials available.</p>
                </div>

                <div *ngIf="_album.length > 0" class="row">
                    <div class="col-6 col-lg-2 col-xl-2 col-md-6 col-sm-6 cursor-pointer"
                        *ngFor="let img of _album; let i = index">
                        <div class="single-courses-box" (click)="reDirectToNewTab(img.attachment)">
                            <div class="courses-image">
                                <a class="d-block image">
                                    <img [src]="img.imgUrl" alt="image" />
                                </a>
                            </div>
                            <div class="courses-content text-center p-2">
                                <h3 class="mb-0" style="font-size: 16px">
                                    {{ img.name }}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <br><br>
                <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                    <div class="pagination-area text-center">
                        <a *ngIf="currentPage > 1" (click)="onPageChange(currentPage - 1)" class="prev page-numbers">
                            <i class="bx bx-chevrons-left"></i>
                        </a>

                        <span *ngFor="let page of visiblePages" (click)="onPageChange(page)" [ngClass]="{
                                'page-numbers': true,
                                current: page === currentPage
                            }" [attr.aria-current]="page === currentPage ? 'page' : null">
                            {{ page }}
                        </span>

                        <a *ngIf="currentPage < totalPages" (click)="onPageChange(currentPage + 1)" class="next page-numbers">
                            <i class="bx bx-chevrons-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-5 widget-area">
                <div class="widget widget_categories fs-6">
                    <div class="fs-6 fw-bolder mb-4 widget-title">{{ courseName }}</div>
                    <ul>

                        <li>
                            <a [routerLink]="['/',courseName, 'sample-classes']" class="align-items-center"
                                routerLinkActive="active" [routerLinkActiveOptions]="{
                            exact: true
                        }">

                                Sample Class ({{ courseDetails.videosCount }})
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/',courseName, 'sample-materials']" class="align-items-center activate-route"
                                routerLinkActive="active" [routerLinkActiveOptions]="{
                            exact: true
                        }">

                                Sample Materials ({{ courseDetails.materialsCount }})
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/',courseName, 'sample-test']" class="align-items-center"
                                routerLinkActive="active" [routerLinkActiveOptions]="{
                            exact: true
                        }">

                                Sample Test ({{ courseDetails.examsCount }})
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/',courseName, 'previous-year-qp']" class="align-items-center"
                                routerLinkActive="active" [routerLinkActiveOptions]="{
                            exact: true
                        }">

                                Previous Year QP({{ courseDetails.preYearCount
                                }})
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/',courseName, 'syllabus']" class="align-items-center"
                                routerLinkActive="active" [routerLinkActiveOptions]="{
                            exact: true
                        }">

                                Syllabus ({{ courseDetails.syllabusCount}})
                            </a>
                        </li>


                    </ul>
                </div>
                <div class="widget widget_odemy_posts_thumb fs-6">
                    <div class="fs-6 fw-bolder mb-4 widget-title">Related Courses</div>
                    <div *ngFor="let course of courseDetails?.relatedCourses" class="fw-bold item">
                        <div *ngIf="course.category?.name" class="info">
                            <span>{{ course.category.name }}</span>
                        </div>
                        <div class="fw-bold fs-6">
                            <a [routerLink]="[categoryName ? '/'+categoryName : '/', course.name, 'sample-classes']"
                                class="align-items-center" routerLinkActive="active" [routerLinkActiveOptions]="{
                                            exact: true
                                        }">
                                {{ course.name }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>
