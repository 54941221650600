import { Component, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MyApiService } from '../../my-api.service';
import { environment } from 'src/app/environment';

@Component({
    selector: 'app-sample-material',
    templateUrl: './sample-material.component.html',
    styleUrl: './sample-material.component.scss',
})
export class SampleMaterialComponent implements OnInit {
    totalItems: number = 0;
    itemsPerPage: number = 15;
    currentPage: number = 1;
    totalPages: number = 0;
    pages: number[] = [];
    _album = [];
    courseName: string;
    categoryName:String;
    courseDetails: {
        examsCount: number;
        materialsCount: number;
        videosCount: number;
        syllabusCount:0;
        preYearCount:0;
        relatedCourses: {
          _id: string;
          name: string;
          category: { _id: string; name: string };
        }[];
      };

    isVisible: any = false;

    constructor(
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private router: Router,
        private myApiService: MyApiService,
        private titleService: Title,
        private metaService: Meta,
    ) {}
    ngOnChanges(changes: SimpleChanges): void {
        throw new Error('Method not implemented.');
    }

    ngOnInit(): void {
        // 'id' should match the query parameter name
        this.route.paramMap.subscribe((params) => {
            this.courseName = params.get('courseName');
            if (this.courseName) {
                this.loadPageData(this.currentPage);
                this.getCourseDetails()

                this.titleService.setTitle(
                    `${this.courseName} - Sample Materials`,
                );

                // Set the meta description for the page
                this.metaService.updateTag({
                    name: 'description',
                    content: `${this.courseName} Sample Materials`,
                });

                // You can also add other meta tags like keywords, author, etc.
                this.metaService.updateTag({
                    name: 'keywords',
                    content: 'sample-materials, angular, seo, website',
                });
            }
        });
    }
    private getCourseDetails() {
        this.courseDetails = undefined;
        this.myApiService
          .getCourseDetails(this.courseName)
          .subscribe(({ data = {} }) => {
            this.courseDetails = data;
    
          });
      }
    

    loadPageData(page: number): void {
        this.myApiService
            .getSampleMaterialData({
                courseNames: [this.route.snapshot.paramMap.get('courseName')],
                pageNo: page,
                limit: this.itemsPerPage,
            })
            .subscribe((res: any) => {
                if (res?.error) {
                    throw res.error;
                }
                if (res?.data) {
                    if (res.data.list.length) {
                        this._album = res.data.list.map((e: any) => ({
                            imgUrl: e.material.imageURL
                                ? e.material.imageURL
                                : 'assets/img/fvlogo.png',
                            name: e.material.name,
                            attachment: e.material.attachment.path,
                        }));
                        this.totalItems = res.data.count;
                        this.totalPages = Math.ceil(
                            this.totalItems / this.itemsPerPage,
                        );
                        if (this.totalPages > 1) {
                            this.updateVisiblePages();
                        }
                    }
                }
            });
    }
    visiblePages: number[] = [];
    updateVisiblePages(): void {
        const maxVisiblePages = 5;
        let startPage = Math.max(
            1,
            this.currentPage - Math.floor(maxVisiblePages / 2),
        );
        let endPage = Math.min(
            this.totalPages,
            startPage + maxVisiblePages - 1,
        );

        // Adjust startPage if we're near the end of the totalPages
        if (
            endPage - startPage + 1 < maxVisiblePages &&
            endPage === this.totalPages
        ) {
            startPage = Math.max(1, this.totalPages - maxVisiblePages + 1);
        }

        this.visiblePages = Array.from(
            { length: endPage - startPage + 1 },
            (_, i) => startPage + i,
        );
    }

    onPageChange(pageNumber: number): void {
        this.currentPage = pageNumber;
        this.loadPageData(this.currentPage);
    }

    reDirectToNewTab(one: string) {
        window.open(`${environment.APP_URL}${one}`, '_blank');
    }
}
