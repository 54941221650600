import { Component } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MyApiService } from '../../my-api.service';
import lgVideo from 'lightgallery/plugins/video';

@Component({
    selector: 'app-syllabus',
    templateUrl: './syllabus.component.html',
    styleUrl: './syllabus.component.scss',
})
export class SyllabusComponent {
    courseName: any;
    courseList: any = [];
    categoryName: any;
    courseDetails: {
        examsCount: number;
        materialsCount: number;
        videosCount: number;
        syllabusCount: 0;
        preYearCount: 0;
        relatedCourses: {
            _id: string;
            name: string;
            category: { _id: string; name: string };
        }[];
    };
    group;
    page;
    pageName;
    groupRoute;

    // courseList: any = [{ name: "boopathi" }];
    constructor(
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private router: Router,
        private myApiService: MyApiService,
        private titleService: Title,
        private metaService: Meta,
    ) {}

    ngOnInit(): void {
        const {
            group = 'Course',
            page = 'syllabus',
            pageName = 'Syllabus',
            groupRoute = 'course',
        } = history.state.data ?? {};
        this.group = group;
        this.groupRoute = groupRoute;
        this.page = group === 'Course' ? 'syllabus' : page;
        this.pageName = group === 'Course' ? 'Syllabus' : pageName;

        this.route.paramMap.subscribe((params) => {
            this.courseName = params.get('courseName');
            this.categoryName = params.get('categoryName');
            // alert(this.categoryName)
            // alert(this.courseName)
            if (this.courseName) {
                this.getCourseDetails();
                this.loadPageData();

                this.titleService.setTitle(`${this.courseName} - Syllabus`);

                // Set the meta description for the page
                this.metaService.updateTag({
                    name: 'description',
                    content: `${this.courseName} Syllabus`,
                });

                // You can also add other meta tags like keywords, author, etc.
                this.metaService.updateTag({
                    name: 'keywords',
                    content: 'Syllabus, angular, seo, website',
                });
            }
        });
    }

    private getCourseDetails() {
        this.courseDetails = undefined;
        this.myApiService
            .getCourseDetails(this.courseName, this.groupRoute)
            .subscribe(({ data = {} }) => {
                this.courseDetails = data;
            });
    }

    loadPageData(): void {
        let api = this.myApiService.getSyallbusData.bind(this.myApiService);
        if (this.group === 'Test Batch Series') {
            if (this.page === 'test-schedule') {
                api = this.myApiService.getTestBatchSeriesTestSchedules.bind(
                    this.myApiService,
                );
            }
        }
        if (this.group === 'Materials') {
            if (this.page === 'sample-materials') {
                api = this.myApiService.getMaterialsSampleMaterials.bind(
                    this.myApiService,
                );
            }
        }

        api({
            courseNames: [this.courseName],
        }).subscribe((res: any) => {
            console.log(res);
            if (res?.error) {
                throw res.error;
            }
            if (res?.data) {
                if (res.data.list.length) {
                    this.courseList = res.data.list;
                }
            }
        });
    }

    settings = {
        counter: false,
        plugins: [lgVideo],
    };
    // onBeforeSlide = (detail: BeforeSlideDetail): void => {
    //     const { index, prevIndex } = detail;
    //     console.log(index, prevIndex);
    // };

    // Tabs
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }

    openSyllabus(url: string) {
        window.open(url, '_blank');
    }

    redirectToSampleVideo() {
        this.myApiService.redirectToSampleVideo({
            group: this.group,
            groupRoute: this.groupRoute,
            page: this.page,
            pageName: this.pageName,
            categoryName: this.categoryName,
            courseName: this.courseName,
        });
    }

    redirectToSampleTest() {
        this.myApiService.redirectToSampleTest({
            group: this.group,
            groupRoute: this.groupRoute,
            page: this.page,
            pageName: this.pageName,
            categoryName: this.categoryName,
            courseName: this.courseName,
        });
    }

    redirectToSyllabus() {
        this.myApiService.redirectToSyllabus({
            group: this.group,
            groupRoute: this.groupRoute,
            page: this.page,
            pageName: this.pageName,
            categoryName: this.categoryName,
            courseName: this.courseName,
        });
    }

    canShowSampleMaterials() {
        return this.myApiService.canShowSampleMaterials({ group: this.group });
    }

    canShowSampleTests() {
        return this.myApiService.canShowSampleTests({ group: this.group });
    }

    canShowPrevYearQP() {
        return this.myApiService.canShowPrevYearQP({ group: this.group });
    }
    getSampleVideoName() {
        return this.myApiService.getSampleVideoName({ group: this.group });
    }

    getSyllabusName() {
        return this.myApiService.getSyllabusName({ group: this.group });
    }
}
