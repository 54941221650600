
<div class="courses-area pt-100 bg-f8f9f8 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">SHINING STARS - OUR TOP PERFORMERS OF THE YEAR</span>
            <h2>Our Achievers</h2>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-3 col-sm-6 col-6" *ngFor="let item of data">
                <div class="start-with-success-box">
                    <div class="image">
                        <img [src]="item.imgPath" [alt]="item.name" />
                    </div>
                    <div class="content" style="text-align: center; margin-top: 10px">
                        <h3 class="item-name achiever-student-name">{{ item.name }}</h3>
                        <span class="item-desc achiever-student-desc">{{ item.desc1 }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container ">
            <br><br>
            <div class="row">
                <div class="section-title">
                    <span class="sub-title">Our Success Stories</span>
                    <h2>Students Testimonials</h2>
                </div>
                <div class="courses-slides">
                    <owl-carousel-o [options]="coursesSlides">
    
                        <ng-template carouselSlide *ngFor="let item of studentMonial">
                            <div class="single-courses-box">
                                <div class="experience-image">
                                    <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                                        <a data-lg-size="1280-720" [attr.data-src]="item.url"
                                            data-sub-html="<p>Watch Video</p>" class="d-block">
                                            <img [src]="item.imgUrl" alt="image">
                                            <div class="video-btn">
                                                <i class="flaticon-play"></i>
                                            </div>
                                        </a>
                                    </lightgallery>
                                    <span class="title">{{item.name}} </span>
                                </div>
                            </div>
                        </ng-template>
    
    
                    </owl-carousel-o>
                </div>
            </div>
            <br>
            <div class="row">
                <!-- <owl-carousel-o [options]="feedbackSlides">
                    <ng-template carouselSlide>
                        <div class="single-feedback-box">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum  ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                            <div class="client-info d-flex align-items-center">
                                <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                <div class="title">
                                    <h3>John Smith</h3>
                                    <span>Python Developer</span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="single-feedback-box">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum  ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                            <div class="client-info d-flex align-items-center">
                                <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                                <div class="title">
                                    <h3>Sarah Taylor</h3>
                                    <span>PHP Developer</span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="single-feedback-box">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum  ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                            <div class="client-info d-flex align-items-center">
                                <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                <div class="title">
                                    <h3>David Warner</h3>
                                    <span>QA Developer</span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o> -->
            </div>
        </div>

       
            <!-- <div class="row">

                <div class="section-title">
                    <span class="sub-title">Our Success Stories</span>
                    <h2>Students Testimonials</h2>
                </div>
                <div class="courses-slides">
                    <owl-carousel-o [options]="coursesSlides">

                        <ng-template carouselSlide *ngFor="let item of studentMonial">
                            <div class="single-courses-box">
                                <div class="experience-image">
                                    <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                                        <a data-lg-size="1280-720" [attr.data-src]="item.url"
                                            data-sub-html="<p>Watch Video</p>" class="d-block">
                                            <img [src]="item.imgUrl" alt="image">
                                            <div class="video-btn">
                                                <i class="flaticon-play"></i>
                                            </div>
                                        </a>
                                    </lightgallery>
                                    <span class="title">{{item.name}} </span>
                                </div>
                            </div>
                        </ng-template>


                    </owl-carousel-o>
                </div>
            </div> -->


        
    </div>
</div>