<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>FAQ's</li>
            </ul>
            <h2>Frequently Asked Questions</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="faq-area ptb-100">
    <div class="container">
        <div class="faq-accordion">
            <div class="accordion">
                <div class="accordion-section">
                    <div class="accordion-header" (click)="toggleSection(0)" [ngClass]="{ 'open': isSectionOpen(0) }">
                        What shipping methods are available?
                    </div>
                    <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(0) }">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                        <p><strong>1.</strong> Sony PlayStation 4 Pro – Best Overall<br>
                            <strong>2.</strong> Xbox One S – Best Gaming Console for Multimedia<br>
                            <strong>3.</strong> Nintendo Switch – Best Hybrid Gaming Console<br>
                            <strong>4.</strong> Nintendo Switch Lite – Best for Portable Play
                        </p>
                    </div>
                </div>
                <div class="accordion-section">
                    <div class="accordion-header" (click)="toggleSection(1)" [ngClass]="{ 'open': isSectionOpen(1) }">
                        What are shipping times and costs?
                    </div>
                    <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(1) }">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                        <ul>
                            <li>a console</li>
                            <li>Two Joy-Con controllers that are detachable</li>
                            <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                            <li>Two straps for turning the Joy-Cons into individual controllers</li>
                            <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                        </ul>
                    </div>
                </div>
                <div class="accordion-section">
                    <div class="accordion-header" (click)="toggleSection(2)" [ngClass]="{ 'open': isSectionOpen(2) }">
                        What payment methods can I use?
                    </div>
                    <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(2) }">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                    </div>
                </div>
                <div class="accordion-section">
                    <div class="accordion-header" (click)="toggleSection(3)" [ngClass]="{ 'open': isSectionOpen(3) }">
                        Can I use my own domain name?
                    </div>
                    <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(3) }">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                    </div>
                </div>
                <div class="accordion-section">
                    <div class="accordion-header" (click)="toggleSection(4)" [ngClass]="{ 'open': isSectionOpen(4) }">
                        What kind of customer service do you offer?
                    </div>
                    <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(4) }">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                        <ul>
                            <li>a console</li>
                            <li>Two Joy-Con controllers that are detachable</li>
                            <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                            <li>Two straps for turning the Joy-Cons into individual controllers</li>
                            <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                        </ul>
                    </div>
                </div>
                <div class="accordion-section">
                    <div class="accordion-header" (click)="toggleSection(5)" [ngClass]="{ 'open': isSectionOpen(5) }">
                        My account says it is locked. How can I unlock it?
                    </div>
                    <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(5) }">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                    </div>
                </div>
                <div class="accordion-section">
                    <div class="accordion-header" (click)="toggleSection(6)" [ngClass]="{ 'open': isSectionOpen(6) }">
                        How often do I have to change my password?
                    </div>
                    <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(6) }">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                        <ul>
                            <li>a console</li>
                            <li>Two Joy-Con controllers that are detachable</li>
                            <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                            <li>Two straps for turning the Joy-Cons into individual controllers</li>
                            <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape12.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape13.png" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape14.png" alt="image"></div>
</div>