<h2 class="mb-0 mt-3 text-center fw-bold" *ngIf="_album.length">
    {{ courseName }} - {{ pageName }}
</h2>
<!-- Gallery Items -->
<div class="gallery-area pt-5 pb-70">
    <div class="container">
        <div *ngIf="_album.length === 0" class="empty-message">
            <p>No exam available.</p>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                <div class="courses-details-desc">
                    <div class="tabs-container">
                        <div class="pane" id="tab2">
                            <div
                                class="courses-curriculum"
                                *ngFor="let course of _album"
                            >
                                <div class="row">
                                    <div class="col-lg-10">
                                        <h3>{{ course.name }}</h3>
                                    </div>
                                </div>

                                <ul *ngFor="let document of course.documents">
                                    <li>
                                        <a
                                            class="popup-youtube d-flex justify-content-between align-items-center"
                                        >
                                            <span class="courses-name">{{
                                                document.title
                                            }}</span>
                                            <div class="courses-meta">
                                                <span
                                                    class="status"
                                                    (click)="
                                                        openExam(document.url)
                                                    "
                                                >
                                                    Start Exam</span
                                                >
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination-area text-center">
                    <a
                        *ngIf="currentPage > 1"
                        (click)="onPageChange(currentPage - 1)"
                        class="prev page-numbers"
                    >
                        <i class="bx bx-chevrons-left"></i>
                    </a>

                    <span
                        *ngFor="let page of visiblePages"
                        (click)="onPageChange(page)"
                        [ngClass]="{
                            'page-numbers': true,
                            current: page === currentPage
                        }"
                        [attr.aria-current]="
                            page === currentPage ? 'page' : null
                        "
                    >
                        {{ page }}
                    </span>

                    <a
                        *ngIf="currentPage < totalPages"
                        (click)="onPageChange(currentPage + 1)"
                        class="next page-numbers"
                    >
                        <i class="bx bx-chevrons-right"></i>
                    </a>
                </div>
            </div>
            <div
                class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-5 widget-area"
            >
                <div class="widget widget_categories fs-6">
                    <div class="fs-6 fw-bolder mb-4 widget-title">
                        {{ courseName }}
                    </div>
                    <ul>
                        <li>
                            <a
                                (click)="redirectToSampleVideo()"
                                class="align-items-center cursor-pointer"
                            >
                                {{ getSampleVideoName() }}
                                ({{ courseDetails.videosCount }})
                            </a>
                        </li>
                        <li *ngIf="canShowSampleMaterials()">
                            <a
                                [routerLink]="[
                                    '/',
                                    courseName,
                                    'sample-materials'
                                ]"
                                class="align-items-center"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{
                                    exact: true
                                }"
                            >
                                Sample Materials ({{
                                    courseDetails.materialsCount
                                }})
                            </a>
                        </li>
                        <li *ngIf="canShowSampleTests()">
                            <a
                                (click)="redirectToSampleTest()"
                                class="align-items-center cursor-pointer activate-route"
                            >
                                Sample Test ({{ courseDetails.examsCount }})
                            </a>
                        </li>
                        <li *ngIf="canShowPrevYearQP()">
                            <a
                                [routerLink]="[
                                    '/',
                                    courseName,
                                    'previous-year-qp'
                                ]"
                                class="align-items-center"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{
                                    exact: true
                                }"
                            >
                                Previous Year QP({{
                                    courseDetails.preYearCount
                                }})
                            </a>
                        </li>
                        <li>
                            <a
                                (click)="redirectToSyllabus()"
                                class="align-items-center cursor-pointer"
                            >
                                {{ getSyllabusName() }}
                                ({{ courseDetails.syllabusCount }})
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="widget widget_odemy_posts_thumb fs-6">
                    <div class="fs-6 fw-bolder mb-4 widget-title">
                        Related Courses
                    </div>
                    <div
                        *ngFor="let course of courseDetails?.relatedCourses"
                        class="fw-bold item"
                    >
                        <div *ngIf="course.category?.name" class="info">
                            <span>{{ course.category.name }}</span>
                        </div>
                        <div class="fw-bold fs-6">
                            <a
                                [routerLink]="[
                                    categoryName ? '/' + categoryName : '/',
                                    course.name,
                                    'sample-classes'
                                ]"
                                class="align-items-center"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{
                                    exact: true
                                }"
                            >
                                {{ course.name }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
