import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { environment } from 'src/app/environment';
import { MyApiService } from '../my-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ILatestUpdate } from '../home/home.interface';
import lgVideo from 'lightgallery/plugins/video';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { OwlOptions } from 'ngx-owl-carousel-o';
interface StudentMonial {
    url: string;
    imgUrl: string;
    name: string;
}
@Component({
    selector: 'app-category-course-list',
    templateUrl: './category-course-list.component.html',
    styleUrl: './category-course-list.component.scss',
})
export class CategoryCourseListComponent implements OnInit {
    categoryId: any;
    categoryName: any;
    courses: any = [];
    testBatches: any = [];
    materials: any = [];
    course: any;
    achievers: any;
    carouselData: any;
    courseName: any;
    latestUpdates: ILatestUpdate[];
    studentMonial: StudentMonial[] = [];
    coursesSlides: OwlOptions = {
        loop: true,
        nav: false,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        navText: [
            "<i class='bx bx-chevron-left'></i>",
            "<i class='bx bx-chevron-right'></i>",
        ],
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 1,
            },
            768: {
                items: 3,
            },
            1200: {
                items: 3,
            },
        },
    };

    constructor(
        private myApiService: MyApiService,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private metaService: Meta,
    ) {}

    ngOnInit(): void {
        // Set the meta description for the page
        this.metaService.updateTag({
            name: 'description',
            content:
                'For Over a decade, Future Vision Study Centre has been a beacon of excellence in education, providing specialized coaching for NEET, TNPSC, and TRB exams in Salem.',
        });

        // You can also add other meta tags like keywords, author, etc.
        this.metaService.updateTag({
            name: 'keywords',
            content: 'home, angular, seo, website',
        });

        this.route.paramMap.subscribe((params) => {
            this.categoryName = params.get('name') ?? 'Category'; // 'id' should match the query parameter name

            this.titleService.setTitle(
                `${this.categoryName} – Future Vision Study Centre`,
            );

            this.myApiService.getCoursesData().subscribe((res: any) => {
                if (res?.error) {
                    throw res.error;
                }

                this.course = res?.data.data?.find(
                    (e) => e.name == this.categoryName,
                );
                this.categoryId = this.course._id;

                this.myApiService
                    .getAllLatestUpdates({ categoryIds: [this.categoryId] })
                    .subscribe((res) => {
                        this.latestUpdates = res?.data?.list ?? [];
                    });

                const formatCourse = (e) => {
                    return {
                        _id: e._id,
                        name: e.name,
                        fees: e.fees ?? [],
                        path: e.attachment
                            ? `${environment.APP_URL}${e.attachment.path}`
                            : 'assets/img/fvlogo.png',
                    };
                };

                this.courseName = this.course.name;
                this.myApiService
                    .getCoursesCategoryDataByName(this.categoryName)
                    .subscribe((res: any) => {
                        if (res?.error) {
                            throw res.error;
                        }
                        if (res?.data)
                            this.courses = res?.data?.courses?.map(formatCourse);
                        this.testBatches =
                            res?.data?.testBatches?.map(formatCourse) ?? [];
                        this.materials = res?.data?.materials?.map(formatCourse) ?? [];
                    });
                this.myApiService
                    .getAchieversData({ categoryId: this.categoryId })
                    .subscribe(
                        (response) => {
                            if (response.data.achievers.length > 0) {
                                this.achievers = response.data.achievers;
                                this.achievers = this.achievers.map(
                                    ({
                                        _id,
                                        name,
                                        desc1,
                                        desc2,
                                        attachment,
                                        fees = [],
                                    }) => {
                                        return {
                                            _id,
                                            name,
                                            desc1,
                                            desc2,
                                            fees,
                                            imgPath: attachment
                                                ? `https://futurevisioniasacademy.com/${attachment.path}`
                                                : 'assets/img/1371716613931.jpg',
                                        };
                                    },
                                );
                            } else {
                                this.achievers = [];
                            }
                        },
                        (error) => {
                            console.error('Error fetching data', error);
                        },
                    );
                this.myApiService.getData(this.categoryId).subscribe(
                    (response) => {
                        if (response.data.sliderImages.length > 0) {
                            this.carouselData = response.data.sliderImages;
                            this.carouselData = this.carouselData.map(
                                (img: any) => {
                                    return {
                                        path: `${environment.APP_URL}/${img.path}`,
                                        name: img.name,
                                        type: 'image',
                                        size: img.size,
                                    };
                                },
                            );
                        } else {
                            this.carouselData = [
                                {
                                    path: 'assets/img/1371716613931.jpg',
                                    name: 'download.png',
                                    type: 'image',
                                    size: '7916',
                                },
                                {
                                    path: 'assets/img/1371716613931.jpg',
                                    name: 'download.png',
                                    type: 'image',
                                    size: '7916',
                                },
                                {
                                    path: 'assets/img/1371716613931.jpg',
                                    name: 'download.png',
                                    type: 'image',
                                    size: '7916',
                                },
                            ];
                        }
                    },
                    (error) => {
                        console.error('Error fetching data', error);
                    },
                );
                this.loadStudentTestMonial(this.categoryId);
            });
        });
    }
    settings = {
        counter: false,
        plugins: [lgVideo],
    };
    onBeforeSlide = (detail: BeforeSlideDetail): void => {
        const { index, prevIndex } = detail;
    };

    loadStudentTestMonial(categoryId) {
        this.myApiService
            .getStudentTestimonialsData({
                categoryIds: [categoryId],
            })
            .subscribe((res: any) => {
                if (res?.error) {
                    throw res.error;
                }
                this.studentMonial = res?.data.list.map((e) => {
                    return {
                        url: `https://www.youtube.com/embed/${e.document.url}?autoplay=1`,
                        imgUrl: e.document.imageURL,
                        name: e.name,
                    };
                });
            });
    }

    reDirectToCourse(courseId: string) {
        window.open(
            `${environment.APP_URL}//auth/sign-up?courseId=${courseId}`,
            '_blank',
        );
    }

    reDirectToCoursePage(courseName: string, route: string) {
        this.router.navigate([this.categoryName, courseName, route], {
            state: {
                data: { group: 'Course', groupRoute: 'course' },
            },
        });
    }

    reDirectToTestSeriesExplanationVideo(courseName: string) {
        this.router.navigate(
            [this.categoryName, courseName, 'explanation-video'],
            {
                state: {
                    data: {
                        group: 'Test Batch Series',
                        groupRoute: 'test-batch-series',
                        page: 'explanation-video',
                        pageName: 'Explanation Video',
                    },
                },
            },
        );
    }

    reDirectToTestSeriesTestSchedule(courseName: string) {
        this.router.navigate([this.categoryName, courseName, 'test-schedule'], {
            state: {
                data: {
                    group: 'Test Batch Series',
                    groupRoute: 'test-batch-series',
                    page: 'test-schedule',
                    pageName: 'Test Schedule',
                },
            },
        });
    }

    reDirectToTestSeriesSampleTest(courseName: string) {
        this.router.navigate([this.categoryName, courseName, 'sample-test'], {
            state: {
                data: {
                    group: 'Test Batch Series',
                    groupRoute: 'test-batch-series',
                    page: 'sample-test',
                    pageName: 'Sample Test',
                },
            },
        });
    }

    reDirectToMaterialsExplanationVideo(courseName: string) {
        this.router.navigate(
            [this.categoryName, courseName, 'explanation-video'],
            {
                state: {
                    data: {
                        group: 'Materials',
                        groupRoute: 'material',
                        page: 'explanation-video',
                        pageName: 'Explanation Video',
                    },
                },
            },
        );
    }

    reDirectToMaterialsSampleMaterials(courseName: string) {
        this.router.navigate(
            [this.categoryName, courseName, 'sample-materials'],
            {
                state: {
                    data: {
                        group: 'Materials',
                        groupRoute: 'material',
                        page: 'sample-materials',
                        pageName: 'Sample Materials',
                    },
                },
            },
        );
    }

    isModalVisible = false;

    fees = [];
    openModal(name, fees) {
        this.fees = fees;
        this.courseName = name;
        this.isModalVisible = true;
    }

    closeModal() {
        this.isModalVisible = false;
    }
}
