<style>
    .start-with-success-box .start-with-success-box {
        border: 1px solid #ddd;
        margin-bottom: 20px;
        border-radius: 5px;
        overflow: hidden;
    }

    .start-with-success-box img {
        width: 100%;
    }

    .start-with-success-box .content {
        padding: 10px;
    }
</style>

<div
    id="carouselExampleIndicators1"
    class="carousel slide"
    data-ride="carousel"
    data-interval="3000"
>
    <!-- The slideshow -->
    <div class="carousel-inner">
        <div
            class="carousel-item"
            *ngFor="let image of carouselData; let first = first"
            [class.active]="first"
        >
            <img [src]="image.path" [alt]="image.name" class="d-block w-100" />
        </div>
    </div>

    <!-- Left and right controls -->
    <a
        class="carousel-control-prev"
        href="#carouselExampleIndicators1"
        role="button"
        data-slide="prev"
    >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a
        class="carousel-control-next"
        href="#carouselExampleIndicators1"
        role="button"
        data-slide="next"
    >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>

<div class="courses-area ptb-100">
    <div class="mt-5 p-2 row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="section-title">
                <h2>{{ courseName }} courses</h2>
            </div>
            <div class="container">
                <div *ngIf="courses.length === 0" class="empty-message">
                    <p>No courses available.</p>
                </div>
                <div class="row" *ngIf="courses.length">
                    <div
                        class="col-sm-12 col-md-3 col-lg-3 col-xl-3"
                        *ngFor="let course of courses"
                    >
                        <div class="single-courses-box">
                            <div class="courses-image">
                                <img [src]="course.path" alt="image" />
                            </div>
                            <div
                                class="courses-content category-course-content"
                            >
                                <h3 style="font-size: 16px">
                                    {{ course.name }}
                                </h3>
                                <div
                                    class="courses-box-footer"
                                    style="text-align: left"
                                >
                                    <div>
                                        &nbsp;&nbsp;<i
                                            class="flaticon-agenda"
                                            style="color: #fe4a55"
                                        ></i
                                        >&nbsp;&nbsp;
                                        <a
                                            (click)="
                                                reDirectToCoursePage(
                                                    course.name,
                                                    'sample-classes'
                                                )
                                            "
                                            class="align-items-center cursor-pointer"
                                        >
                                            Sample Classes
                                        </a>
                                    </div>

                                    <div>
                                        &nbsp;&nbsp;<i
                                            class="flaticon-people"
                                            style="color: #fe4a55"
                                        ></i
                                        >&nbsp;&nbsp;
                                        <a
                                            (click)="
                                                reDirectToCoursePage(
                                                    course.name,
                                                    'sample-materials'
                                                )
                                            "
                                            class="align-items-center cursor-pointer"
                                        >
                                            Sample Materials
                                        </a>
                                    </div>
                                    <div>
                                        &nbsp;&nbsp;<i
                                            class="flaticon-calendar"
                                            style="color: #fe4a55"
                                        ></i
                                        >&nbsp;&nbsp;
                                        <a
                                            (click)="
                                                reDirectToCoursePage(
                                                    course.name,
                                                    'sample-test'
                                                )
                                            "
                                            class="align-items-center cursor-pointer"
                                        >
                                            Sample Test
                                        </a>
                                    </div>
                                    <div>
                                        &nbsp;&nbsp;<i
                                            class="flaticon-distance-learning"
                                            style="color: #fe4a55"
                                        ></i
                                        >&nbsp;&nbsp;
                                        <a
                                            (click)="
                                                reDirectToCoursePage(
                                                    course.name,
                                                    'previous-year-qp'
                                                )
                                            "
                                            class="align-items-center"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                        >
                                            Previous year QP
                                        </a>
                                    </div>
                                    <div>
                                        &nbsp;
                                        <i
                                            class="flaticon-web"
                                            style="color: #fe4a55"
                                        ></i
                                        >&nbsp;&nbsp;
                                        <a
                                            (click)="
                                                reDirectToCoursePage(
                                                    course.name,
                                                    'syllabus'
                                                )
                                            "
                                            class="align-items-center"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                        >
                                            Syllabus
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <i
                                        class="flaticon-credit-card-1"
                                        style="color: #fe4a55"
                                    ></i
                                    >&nbsp;&nbsp;
                                    <a
                                        (click)="
                                            openModal(course.name, course.fees)
                                        "
                                        class="align-items-center cursor-pointer"
                                    >
                                        Fee Structure
                                    </a>
                                </div>

                                <!-- <app-course-fee [fees]="course.fees"></app-course-fee> -->
                                <br />
                                <a
                                    style="margin-left: 20%"
                                    (click)="reDirectToCourse(course._id)"
                                    class="default-btn align-items-center cursor-pointer"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                >
                                    Join Now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5 p-2 row" *ngIf="testBatches.length">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="section-title">
                <h2>{{ courseName }} Test Batch Series</h2>
            </div>
            <div class="container">
                <div *ngIf="testBatches.length === 0" class="empty-message">
                    <p>No test batches available.</p>
                </div>
                <div class="row" *ngIf="testBatches.length">
                    <div
                        class="col-sm-12 col-md-3 col-lg-3 col-xl-3"
                        *ngFor="let course of testBatches"
                    >
                        <div class="single-courses-box">
                            <div class="courses-image">
                                <img [src]="course.path" alt="image" />
                            </div>
                            <div
                                class="courses-content category-course-content"
                            >
                                <h3 style="font-size: 16px">
                                    {{ course.name }}
                                </h3>
                                <div
                                    class="courses-box-footer"
                                    style="text-align: left"
                                >
                                    <div>
                                        &nbsp;&nbsp;<i
                                            class="flaticon-agenda"
                                            style="color: #fe4a55"
                                        ></i
                                        >&nbsp;&nbsp;
                                        <a
                                            (click)="
                                                reDirectToTestSeriesExplanationVideo(
                                                    course.name
                                                )
                                            "
                                            class="align-items-center cursor-pointer"
                                        >
                                            Explanation Video
                                        </a>
                                    </div>

                                    <div>
                                        &nbsp;&nbsp;<i
                                            class="flaticon-people"
                                            style="color: #fe4a55"
                                        ></i
                                        >&nbsp;&nbsp;
                                        <a
                                            (click)="
                                                reDirectToTestSeriesTestSchedule(
                                                    course.name
                                                )
                                            "
                                            class="align-items-center cursor-pointer"
                                        >
                                            Test Schedule
                                        </a>
                                    </div>
                                    <div>
                                        &nbsp;&nbsp;<i
                                            class="flaticon-calendar"
                                            style="color: #fe4a55"
                                        ></i
                                        >&nbsp;&nbsp;
                                        <a
                                            (click)="
                                                reDirectToTestSeriesSampleTest(
                                                    course.name
                                                )
                                            "
                                            class="align-items-center cursor-pointer"
                                        >
                                            Sample Test
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <i
                                        class="flaticon-credit-card-1"
                                        style="color: #fe4a55"
                                    ></i
                                    >&nbsp;&nbsp;
                                    <a
                                        (click)="
                                            openModal(course.name, course.fees)
                                        "
                                        class="align-items-center"
                                    >
                                        Fee Structure
                                    </a>
                                </div>

                                <!-- <app-course-fee [fees]="course.fees"></app-course-fee> -->
                                <br />
                                <a
                                    style="margin-left: 20%"
                                    (click)="reDirectToCourse(course._id)"
                                    class="default-btn align-items-center cursor-pointer"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                >
                                    Join Now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-5 p-2 row" *ngIf="materials.length">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="section-title">
                <h2>{{ courseName }} Materials</h2>
            </div>
            <div class="container">
                <div *ngIf="materials.length === 0" class="empty-message">
                    <p>No materials available.</p>
                </div>
                <div class="row" *ngIf="materials.length">
                    <div class="row">
                        <div
                            class="col-sm-12 col-md-3 col-lg-3 col-xl-3"
                            *ngFor="let course of materials"
                        >
                            <div class="single-courses-box">
                                <div class="courses-image">
                                    <img [src]="course.path" alt="image" />
                                </div>
                                <div
                                    class="courses-content category-course-content"
                                >
                                    <h3 style="font-size: 16px">
                                        {{ course.name }}
                                    </h3>
                                    <div
                                        class="courses-box-footer"
                                        style="text-align: left"
                                    >
                                        <div>
                                            &nbsp;&nbsp;<i
                                                class="flaticon-agenda"
                                                style="color: #fe4a55"
                                            ></i
                                            >&nbsp;&nbsp;
                                            <a
                                                (click)="
                                                    reDirectToMaterialsExplanationVideo(
                                                        course.name
                                                    )
                                                "
                                                class="align-items-center cursor-pointer"
                                            >
                                                Explanation Video
                                            </a>
                                        </div>
                                        <div>
                                            &nbsp;&nbsp;<i
                                                class="flaticon-calendar"
                                                style="color: #fe4a55"
                                            ></i
                                            >&nbsp;&nbsp;
                                            <a
                                                (click)="
                                                    reDirectToMaterialsSampleMaterials(
                                                        course.name
                                                    )
                                                "
                                                class="align-items-center cursor-pointer"
                                            >
                                                Sample Materials
                                            </a>
                                        </div>
                                    </div>
                                    <div>
                                        <i
                                            class="flaticon-credit-card-1"
                                            style="color: #fe4a55"
                                        ></i
                                        >&nbsp;&nbsp;
                                        <a
                                            (click)="
                                                openModal(
                                                    course.name,
                                                    course.fees
                                                )
                                            "
                                            class="align-items-center"
                                        >
                                            Fee Structure
                                        </a>
                                    </div>

                                    <!-- <app-course-fee [fees]="course.fees"></app-course-fee> -->
                                    <br />
                                    <a
                                        style="margin-left: 20%"
                                        (click)="reDirectToCourse(course._id)"
                                        class="default-btn align-items-center cursor-pointer"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                    >
                                        Join Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section-title">
                    <h2>{{ courseName }} Achievers</h2>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="start-with-success-area bg-f8f9f8">
                <div class="container">
                    <div class="row">
                        <div
                            *ngIf="achievers.length === 0"
                            class="empty-message"
                        >
                            <p>No achievers available.</p>
                        </div>
                        <div class="row" *ngIf="achievers.length > 0">
                            <div
                                class="col-lg-2 col-md-2 col-sm-6"
                                *ngFor="let item of achievers"
                            >
                                <div class="start-with-success-box">
                                    <div class="image">
                                        <!--                                    <a [routerLink]="'/success-story'">-->
                                        <!--                                        <img-->
                                        <!--                                            [src]="item.imgPath"-->
                                        <!--                                            [alt]="item.name"-->
                                        <!--                                        />-->
                                        <!--                                    </a>-->

                                        <img
                                            [src]="item.imgPath"
                                            [alt]="item.name"
                                        />
                                    </div>
                                    <div
                                        class="content"
                                        style="
                                            text-align: center;
                                            margin-top: 10px;
                                        "
                                    >
                                        <h3 style="font-size: 17px">
                                            {{ item.name }}
                                        </h3>
                                        <span>{{ item.desc1 }}</span>
                                        <!-- <h3 style="font-size: 17px;">{{ item.desc2 }}</h3> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div class="container">
            <br /><br />
            <div class="row">
                <div class="section-title">
                    <span class="sub-title">Our Success Stories</span>
                    <h2>Students Testimonials</h2>
                </div>
                <div class="courses-slides">
                    <owl-carousel-o [options]="coursesSlides">
                        <ng-template
                            carouselSlide
                            *ngFor="let item of studentMonial"
                        >
                            <div class="single-courses-box">
                                <div class="experience-image">
                                    <lightgallery
                                        [settings]="settings"
                                        [onBeforeSlide]="onBeforeSlide"
                                    >
                                        <a
                                            data-lg-size="1280-720"
                                            [attr.data-src]="item.url"
                                            data-sub-html="<p>Watch Video</p>"
                                            class="d-block"
                                        >
                                            <img
                                                [src]="item.imgUrl"
                                                alt="image"
                                            />
                                            <div class="video-btn">
                                                <i class="flaticon-play"></i>
                                            </div>
                                        </a>
                                    </lightgallery>
                                    <span class="title">{{ item.name }} </span>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
            <br />
            <div class="row">
                <!-- <owl-carousel-o [options]="feedbackSlides">
                <ng-template carouselSlide>
                    <div class="single-feedback-box">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum  ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                        <div class="client-info d-flex align-items-center">
                            <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                            <div class="title">
                                <h3>John Smith</h3>
                                <span>Python Developer</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-box">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum  ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                        <div class="client-info d-flex align-items-center">
                            <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                            <div class="title">
                                <h3>Sarah Taylor</h3>
                                <span>PHP Developer</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-box">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum  ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                        <div class="client-info d-flex align-items-center">
                            <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                            <div class="title">
                                <h3>David Warner</h3>
                                <span>QA Developer</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o> -->
            </div>
        </div>

    </div>

</div>


<div class="modal" [ngClass]="{ 'modal-open': isModalVisible }">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    {{ courseName }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="row text-center">
                    <div
                        *ngFor="let fee of fees"
                        class="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 fw-bold"
                    >
                        <div>
                            {{
                            fee.type === "Online"
                                ? "Online"
                                : "Direct"
                            }}
                            Class Fee
                        </div>
                        <div class="text-danger">INR {{ fee.fee }}</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="closeModal()"
                >
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

<app-subscribe-style-three></app-subscribe-style-three>
