<footer class="footer-area">
    <div class="mx-8">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xl-4">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logowide.png" style="width:120px;height:40px" alt="logo"></a>
                 <p>With a dedicated team of experienced faculty and a commitment to quality education, we have established ourselves as the leading coaching institute in the region.</p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/futurevisionneet" class="d-block cursor-pointer" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/future_vision_study_centre" class="d-block cursor-pointer" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.youtube.com/@FUTUREVISIONNEETACADEMY-jg3qr" class="d-block cursor-pointer" target="_blank"><i class='bx bxl-youtube'></i></a></li>

                        <li><a href="https://t.me/futurevisionneetacademy" class="d-block cursor-pointer" target="_blank"><i class='bx bxl-telegram'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xl-4">
                <div class="single-footer-widget">
                    <h3>Future Vision Study Centre</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>FUTURE VISION STUDY CENTRE,<br/> 2ND  Floor, AVK Maruthi  Plaza,<br/> Opp.Hotel Lakshmi  Prakash, New Bus Stand,<br/> Salem – 636004.</li>
                        <li><i class='bx bx-phone-call'></i>Contact No<a href="tel:+919042030163 ">+91 90420 30163</a></li>
                        <li><i class='bx bx-phone-call'></i>WhatsApp No<a href="tel:+919042030163">+91 90420 30163</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:futurevisionteam&#64;gmail.com">futurevisionteam&#64;gmail.com</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xl-4">
                <div class="single-footer-widget">
                    <h3>Future Vision Neet/JEE Mains Academy </h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>FUTURE VISION  NEET ACADEMY,<br/> 2ND  Floor, AVK Maruthi  Plaza,<br/> Opp.Hotel Lakshmi  Prakash, New Bus Stand,<br/> Salem – 636004.</li>
                        <li><i class='bx bx-phone-call'></i>Contact No<a href="tel:+919443922215">+91 94439 22215</a></li>
                        <li><i class='bx bx-phone-call'></i>WhatsApp No<a href="tel:+919443922215">+91 94439 22215</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:futurevisionteam&#64;gmail.com">futurevisionteam&#64;gmail.com</a></li>
                    </ul>
                </div>
            </div>

        </div>
        <div class="footer-bottom-area mt-1">
            <div class="d-flex justify-content-between">
                <div>
                    <ul>
                        <li><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Home</a></li>
                        <li><a routerLink="/about" routerLinkActive="active">About</a></li>
                        <li><a routerLink="/gallery/infra" routerLinkActive="active">Gallery</a></li>
                        <li><a routerLink="/contact" routerLinkActive="active">Contact</a></li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li><a href="https://futurevisioniasacademy.com/refund_policy" target="_blank">Refund Policy</a></li>
                        <li><a href="https://futurevisioniasacademy.com/privacy_policy" target="_blank">Privacy Policy</a></li>
                        <li><a href="https://futurevisioniasacademy.com/terms_conditions" target="_blank">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
